// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { GoogleAuthProvider, getAuth, signInWithPopup, UserCredential } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries



// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCqBi0fzfRuRx6YyEjEpRZk4DAAo8JPosw",
  authDomain: "test-website-178d4.firebaseapp.com",
  projectId: "test-website-178d4",
  storageBucket: "test-website-178d4.appspot.com",
  messagingSenderId: "134860618230",
  appId: "1:134860618230:web:0733adc201bb8105f96fd7",
  measurementId: "G-3XY95DX5KC",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const firestore = getFirestore(app);
// export const functions = getFunctions(app);

// if (process.env.NODE_ENV === 'development') {
//   connectFunctionsEmulator(functions, 'localhost', 5001);
// }

export const googleProvider = new GoogleAuthProvider();

export const loginWithGoogle = async (): Promise<UserCredential> => {
    return await signInWithPopup(auth, googleProvider);
};