// VocabularyListPage.tsx
import React, { useState } from "react";
import VocabularyListGenerator from "../components/VocabularyListGenerator";
import NumberedList from "../components/NumberedList";
import { app } from "../firebaseConfig";
import { getFunctions, httpsCallable } from "firebase/functions";
import { FUNCTION_NAMES, VocabularyListInput } from "slp-tools-shared";
import BetaDisclaimer from "../components/BetaDisclaimerComponent";
import CopyToClipboardButton from "../components/CopyToClipboardButton";

export const VocabularyListPage: React.FC = () => {
  const [generatedWords, setGeneratedWords] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handlePromptSubmit = async (paramaters: VocabularyListInput) => {
    callVocabularyListGenerator(paramaters).then((response) =>
      handleGeneratedList(response)
    );
  };

  const callVocabularyListGenerator = async (
    paramaters: VocabularyListInput
  ): Promise<string> => {
    setIsLoading(true);
    try {
      const functionsInstance = getFunctions(app);
      const vocabularyFunction = httpsCallable(
        functionsInstance,
        FUNCTION_NAMES.vocabularyWordListGenerator
      );
      const result = await vocabularyFunction(paramaters);
      const responseData = result.data as string;
      return responseData;
    } catch (error) {
      return "";
    } finally {
      setIsLoading(false);
    }
  };

  const handleGeneratedList = (result: string) => {
    const words = result.split(/\s*\d+\.\s*/).slice(1); // Extract words from the numbered list
    setGeneratedWords(words);
  };

  return (
    <main className="container mx-auto px-4 py-8">
      <BetaDisclaimer />
      <section className="text-center mb-8">
        <h2 className="text-4xl font-bold mb-4">Vocabulary List Generator</h2>
        <p className="text-xl font-semibold">
          Generate customized vocabulary lists based on the topic and difficulty
          level.
        </p>
      </section>
      <div className="flex flex-col lg:flex-row">
        <section className="w-full lg:w-1/2 mb-8">
          <VocabularyListGenerator onSubmit={handlePromptSubmit} />
        </section>
        <section className="w-full lg:w-1/2">
          {isLoading ? (
            <div className="flex justify-center items-center mt-8">
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
            </div>
          ) : (
            generatedWords.length > 0 && (
              <>
                <NumberedList words={generatedWords} />{" "}
                <CopyToClipboardButton textToCopy={generatedWords.join("\n")} />
              </>
            )
          )}
        </section>
      </div>
    </main>
  );
};

export default VocabularyListPage;
