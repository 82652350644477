import React from "react";
import { Footer } from "flowbite-react";
import { ReactComponent as Logo } from "../assets/logos/svg/color_logo_no_background.svg";

const SiteFooter: React.FC = () => {
  return (
    <Footer className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-6 flex items-center justify-between">
      <div className="container mx-auto px-4 flex items-center justify-between">
        
        {/* Logo and Brand */}
        <div className="flex items-center">
          <Logo className="h-8 w-auto mr-3" />
        </div>

        {/* Copyright Info */}
        <div>
          <span>&copy; 2023 SLP.tools. All Rights Reserved.</span>
        </div>

      </div>
    </Footer>
  );
};

export default SiteFooter;
