import React from "react";
import { Link } from "react-router-dom";

export const ToolsPage: React.FC = () => {
  return (
    <main className="container mx-auto px-4 py-8">
      <section className="text-center">
        <h2 className="text-4xl font-bold mb-4">Available Tools</h2>
        <p className="text-xl font-semibold mb-6">
          Explore the AI-powered tools designed for SLPs
        </p>
      </section>
      <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-12">
        <Link
          to="/tools/articulation-word-list"
          className="border border-blue-500 rounded-md p-4 text-center hover:bg-blue-100"
        >
          <h3 className="text-2xl font-bold">
            Articulation Word List Generator
          </h3>
          <p>
            Generate customized articulation word lists based on your client's
             interests and target phonemes.
          </p>
        </Link>
        <Link
          to="/tools/social-story"
          className="border border-blue-500 rounded-md p-4 text-center hover:bg-blue-100"
        >
          <h3 className="text-2xl font-bold">Social Story Generator</h3>
          <p>Create customized social stories to support your clients.</p>
        </Link>
        <Link
          to="/tools/vocabulary-list"
          className="border border-blue-500 rounded-md p-4 text-center hover:bg-blue-100"
        >
          <h3 className="text-2xl font-bold">Vocabulary List Generator</h3>
          <p>Create customized vocabulary lists to support your clients.</p>
        </Link>

        {/* Add more tools as needed */}
      </section>
    </main>
  );
};
