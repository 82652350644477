// Tooltip.tsx
import React, { useState } from "react";
import { FiInfo } from "react-icons/fi";
import { Tooltip } from "flowbite-react";

interface TooltipComponentProps {
  text: string;
}

const TooltipComponent: React.FC<TooltipComponentProps> = ({ text }) => {
  return (
    <Tooltip content={text} style="light" placement="right" >
      <FiInfo
          color="gray"
          className="mx-2"
        ></FiInfo>
    </Tooltip>
  );
};

export default TooltipComponent;
