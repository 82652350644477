// import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { AuthProvider, useAuth } from "../components/AuthProvider";

import { SocialStoryPage } from "./SocialStoryPage";
import { VocabularyListPage } from "./VocabularyListPage";
import { HomePage } from "./HomePage";
import { ArticulationWordListPage } from "./ArticulationWordListPage";
import { ToolsPage } from "./ToolsPage";
import Header from "../components/Header";
import About from "./AboutPage";

// import { Toast } from "flowbite-react";
import { LoginButton } from "../components/LoginButton";

const LandingPage: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="min-h-screen bg-gray-100 flex flex-col">
          <Header />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<About />} />
              <Route
                path="/tools"
                element={
                  <RequireAuth>
                    <ToolsPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/tools/social-story"
                element={
                  <RequireAuth>
                    <SocialStoryPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/tools/articulation-word-list"
                element={
                  <RequireAuth>
                    <ArticulationWordListPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/tools/vocabulary-list"
                element={
                  <RequireAuth>
                    <VocabularyListPage />
                  </RequireAuth>
                }
              />
            </Routes>
          </main>
          {/* ... footer ... */}
        </div>
      </Router>
    </AuthProvider>
  );
};

export default LandingPage;

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user) {
    return (
      <div className="flex flex-col items-center justify-center bg-gray-100 p-6 container mx-auto px-4 py-32">
        <div className="w-full max-w-md p-8 bg-white rounded-xl shadow-md">
          <h2 className="text-3xl font-semibold text-center text-gray-800 mb-6">
            Welcome!
          </h2>
          <p className="text-md text-center text-gray-600 mb-6">
            Please sign in with Google to continue.
          </p>
          <div className="flex justify-center">
            <LoginButton className="px-6 py-3 text-lg font-medium text-gray-800 bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-50 shadow" />
          </div>
        </div>
      </div>
    );
  }

  return children;
}
