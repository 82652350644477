import React, { useState, useEffect } from "react";
import { TextInput, Button, Label, Select } from "flowbite-react";
import Tooltip from "./Tooltip";
import { VocabularyListInput } from "slp-tools-shared";

interface VocabularyListGeneratorProps {
  onSubmit: (parameters: VocabularyListInput) => void;
}

const gradeLevels = [
  "Pre-K",
  "Kindergarten",
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
  "11th",
  "12th",
];


const VocabularyListGenerator: React.FC<VocabularyListGeneratorProps> = ({
  onSubmit,
}) => {
  const [topic, setTopic] = useState("");
  const [numberOfWords, setNumberOfWords] = useState("");
  const [type, setType] = useState("");
  const [grade, setGrade] = useState(gradeLevels[0]);
  const [gradeRequired, setGradeRequired] = useState(false);

  useEffect(() => {
    setGradeRequired(type === "Grade level vocabulary");
  }, [type]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    const vocabularyListData: VocabularyListInput = {
      topic,
      numberOfWords,
      type,
      grade,
      gradeRequired
    };

    onSubmit(vocabularyListData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 w-full max-w-md mx-auto">
      {/* Topic */}
      <div className="flex flex-col space-y-2">
        <div className="flex items-center">
          <Label htmlFor="topic" value="Topic" className="text-left" />
          <Tooltip text="Topic/theme of the word list." />
        </div>
        <TextInput
          id="topic"
          type="text"
          placeholder="Topic"
          value={topic}
          onChange={(e) => setTopic(e.target.value)}
          className="w-full"
        />
      </div>

      {/* Number of words */}
      <div className="flex flex-col space-y-2">
        <div className="flex items-center">
          <Label
            htmlFor="numberOfWords"
            value="Number of Sets/Words"
            className="text-left"
          />
          <Tooltip text="Number of sets/words to include in the list." />
        </div>

        <TextInput
          id="numberOfWords"
          type="number"
          placeholder="Number of Sets/Words"
          min={1}
          max={20}
          defaultValue={10}
          value={numberOfWords}
          onChange={(e) => setNumberOfWords(e.target.value)}
          required={true}
          className="w-full"
        />
      </div>

      {/* Type */}
      <div className="flex flex-col space-y-2">
        <div className="flex items-center">
          <Label htmlFor="type" value="Type" className="text-left" />
          <Tooltip text="Type of vocabulary list." />
        </div>
        <Select
          id="type"
          value={type}
          onChange={(e) => setType(e.target.value)}
          required={true}
          className="w-full"
        >
          <option value="">Select a type</option>
          <option value="Synonyms">Synonym sets</option>
          <option value="Antonyms">Antonym sets</option>
          <option value="Homonyms">Homonym sets</option>
          <option value="Grade level vocabulary">Grade level vocabulary</option>
          <option value="Temporal">Temporal (time)</option>
          <option value="Quantity">Quantity (number)</option>
          <option value="Quality">Quality (describing)</option>
          <option value="Social-Emotional">Social-Emotional (feelings)</option>
          <option value="Spatial">Spatial (location)</option>
        </Select>
      </div>

      {/* Grade level */}
      <div className="flex flex-col space-y-2">
        <Label htmlFor="gradeLevel" value="Grade Level" className="text-left" />
        <Select
          id="gradeLevel"
          value={grade}
          onChange={(e) => setGrade(e.target.value)}
          className="w-full text-base"
        >
          {gradeLevels.map((grade) => (
            <option key={grade} value={grade}>
              {grade}
            </option>
          ))}
        </Select>
      </div>

      <Button type="submit" color="purple">
        Generate List
      </Button>
    </form>
  );
};

export default VocabularyListGenerator;
