import React from "react";
import { useAuth } from "../useAuth";
import { AiOutlineFileText } from "react-icons/ai";
import { BsChatDots } from "react-icons/bs";
import { RiBookOpenLine } from "react-icons/ri";
import { AiFillTool } from 'react-icons/ai';  // Icon for choosing a tool
import { BiDetail } from 'react-icons/bi';    // Icon for inputting preferences
import { IoMdSpeedometer } from 'react-icons/io'; // Icon for AI-powered results
import SiteFooter from "../components/Footer";

export const HomePage: React.FC = () => {
  const { user } = useAuth();

  return (
    <main className="container mx-auto px-4 py-4">
      {/* Hero Section */}
      <section className="text-center my-4">
        <section className="bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 text-white text-center py-20">
          <h1 className="text-6xl font-extrabold">
            Empowering Speech-Language Pathologists
          </h1>
          <p className="text-2xl mt-4">
            AI-powered tools to make your work more efficient and effective.
          </p>
          <div className="mt-8">
            <a
              href="tools"
              className="bg-white text-purple-600 hover:bg-purple-100 text-lg font-semibold py-2 px-6 rounded-full"
            >
              Explore Tools
            </a>
          </div>
        </section>
      </section>

      {/* About Section */}
      <section id="about" className="my-20 px-8">
        <h2 className="text-4xl font-bold text-center mb-10">
          About SLP.tools
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* Mission Statement */}
          <div>
            <h3 className="text-2xl font-semibold mb-4">Our Mission</h3>
            <p className="text-lg">
              Our goal is to simplify and enhance the workflow of
              Speech-Language Pathologists. We provide tools that harness the
              power of AI to create customizable, relevant, and efficient
              materials for therapy.
            </p>
          </div>
          {/* Why AI */}
          <div>
            <h3 className="text-2xl font-semibold mb-4">Why AI?</h3>
            <p className="text-lg">
              Artificial Intelligence enables us to create tools that adapt to
              the specific needs and goals of each client. This ensures that
              each intervention is as effective as possible, saving time and
              yielding better outcomes.
            </p>
          </div>
        </div>
      </section>

      {/* Tools We Offer */}
      <section id="tools" className="my-16 py-16">
        <h2 className="text-4xl font-bold text-center mb-12">Tools We Offer</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {/* Tool 1 */}
          <div className="bg-white p-8 rounded-lg shadow-md hover:shadow-lg transition flex flex-col items-center">
            <div className="text-center mb-6">
              <AiOutlineFileText className="text-6xl text-gray-300" />
            </div>
            <h3 className="text-2xl font-semibold mb-4">
              Articulation Word List Generation
            </h3>
            <p className="text-lg mb-4">
              Generate customized articulation word lists based on your client's
              target phonemes.
            </p>
          </div>
          {/* Tool 2 */}
          <div className="bg-white p-8 rounded-lg shadow-md hover:shadow-lg transition flex flex-col items-center">
            <div className="text-center mb-6">
              <BsChatDots className="text-6xl text-gray-300" />
            </div>
            <h3 className="text-2xl font-semibold mb-4">
              Social Story Generation
            </h3>
            <p className="text-lg mb-4">
              Create personalized social stories tailored to your client's needs
              and interests to improve social skills.
            </p>
          </div>
          {/* Tool 3 */}
          <div className="bg-white p-8 rounded-lg shadow-md hover:shadow-lg transition flex flex-col items-center">
            <div className="text-center mb-6">
              <RiBookOpenLine className="text-6xl text-gray-300" />
            </div>
            <h3 className="text-2xl font-semibold mb-4">
              Vocabulary List Generation
            </h3>
            <p className="text-lg mb-4">
              Build vocabulary lists targeting specific themes, age groups, and
              difficulty levels to support language development.
            </p>
          </div>
          <div className="col-span-full flex justify-center items-center">
            <a href="tools/articulation-word-list" className="text-purple-600 hover:underline text-2xl">
              Try them out!
            </a>
          </div>
        </div>
      </section>

      <section className="my-16">
        <h2 className="text-4xl font-bold text-center mb-10">How It Works</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-white p-8 rounded-lg shadow-md hover:shadow-lg transition flex flex-col items-center">
            <div className="text-center mb-6">
              <AiFillTool className="text-6xl text-gray-300 mb-4" />
            </div>
            <h3 className="text-2xl font-semibold mb-4">1. Choose a Tool</h3>
            <p>
              Start by selecting the AI tool that fits your specific needs and
              preferences.
            </p>
          </div>

          <div className="bg-white p-8 rounded-lg shadow-md hover:shadow-lg transition flex flex-col items-center">
            <div className="text-center mb-6">
              <BiDetail className="text-6xl text-gray-300 mb-4" />
            </div>
            <h3 className="text-2xl font-semibold mb-4">2. Input Preferences</h3>
            <p>
              Provide specifics, like target phonemes or story themes, to
              customize the tool's output.
            </p>
          </div>

          <div className="bg-white p-8 rounded-lg shadow-md hover:shadow-lg transition flex flex-col items-center">
            <div className="text-center mb-6">
              <IoMdSpeedometer className="text-6xl text-gray-300 mb-4" />
            </div>
            <h3 className="text-2xl font-semibold mb-4">
              3. Get AI-Powered Results
            </h3>
            <p>
              Receive tailored results, powered by state-of-the-art AI
              technology.
            </p>
          </div>
        </div>
      </section>

      {/* Testimonials */}
      <section className="my-10">{/* Content goes here */}</section>

      {/* Newsletter Signup */}
      <section className="my-10">{/* Content goes here */}</section>

      {/* Footer */}
      <SiteFooter></SiteFooter>
    </main>
  );
};
