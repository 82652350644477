import React, { useState } from "react";
import ArticulationWordListGenerator from "../components/ArticulationWordListGenerator";
import { app } from "../firebaseConfig";
import { getFunctions, httpsCallable } from "firebase/functions";
import NumberedList from "../components/NumberedList";
import { ArticulationWordListInput } from "slp-tools-shared";
import { FUNCTION_NAMES } from "slp-tools-shared";
import BetaDisclaimer from "../components/BetaDisclaimerComponent";
import CopyToClipboardButton from "../components/CopyToClipboardButton";


export const ArticulationWordListPage: React.FC = () => {
  const [generatedWords, setGeneratedWords] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleGeneratedList = (result: string) => {
    // Use a regex that looks for numbered entries and captures the subsequent words
    const wordSetRegex = /\d+\.\s*([^\n]+)(?:\n|$)/g;
    let match;
    const words = [];
  
    while ((match = wordSetRegex.exec(result)) !== null) {
      words.push(match[1].trim()); // Capture the group after the number
    }
    
    setGeneratedWords(words);
  };

  const handlePromptSubmit = async (paramaters: ArticulationWordListInput) => {
    callArticulationWordListGenerator(paramaters).then((response) =>
      handleGeneratedList(response)
    );
  };

  const callArticulationWordListGenerator = async (paramaters: ArticulationWordListInput) : Promise<string> => {
    setIsLoading(true);
    try {
      const functionsInstance = getFunctions(app);
      const socialStoryFunction = httpsCallable(
        functionsInstance,
        FUNCTION_NAMES.articulationWordListGenerator
      );
      const result = await socialStoryFunction(paramaters);
      const responseData = result.data as string;
      return responseData;
    } catch (error) {
      return "";
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main className="container mx-auto px-4 py-8">
      <BetaDisclaimer />
      <section className="text-center mb-8">
        <h2 className="text-4xl font-bold mb-4">
          Articulation Word List Generator
        </h2>
        <p className="text-xl font-semibold">
          Generate customized articulation word lists based on your client's
          interests and target phonemes.
        </p>
      </section>
      <div className="flex flex-col lg:flex-row">
        <section className="w-full lg:w-1/2 mb-8">
          <ArticulationWordListGenerator onSubmit={handlePromptSubmit} />
        </section>
        <section className="w-full lg:w-1/2">
          {isLoading ? (
            <div className="flex justify-center items-center mt-8">
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
            </div>
          ) : (
            generatedWords.length > 0 && (<><NumberedList words={generatedWords} /><CopyToClipboardButton textToCopy={generatedWords.join("\n")}/></>)
          )}
        </section>
      </div>
    </main>
  );
};
