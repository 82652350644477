// src/LogoutButton.tsx
import React from 'react';
import { auth } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';

interface LogoutButtonProps {
  onLogout?: () => void;
  className?: string;
}

const LogoutButton: React.FC<LogoutButtonProps> = ({ onLogout, className = "flex items-center px-4 py-2 space-x-2 text-white bg-red-500 rounded hover:bg-red-600" }) => {
  const handleLogout = async () => {
    try {
      await auth.signOut();
      onLogout && onLogout();
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  return (
    <button onClick={handleLogout} className={className}>
      Logout
    </button>
  );
};

export default LogoutButton;
