import React from "react";
import { Navbar, Dropdown } from "flowbite-react";
import { useAuth } from "../useAuth";
import { ReactComponent as Logo } from "../assets/logos/svg/color_logo_no_background.svg";
import LogoutButton from "./LogoutButton";
import { LoginButton } from "./LoginButton";
import { MdOutlineAccountCircle } from "react-icons/md";

const Header: React.FC = () => {
  const { loading, user } = useAuth();

  return (
    <Navbar
      fluid={true}
      rounded={true}
      className="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-none"
    >
      <Navbar.Brand href="/">
        <Logo className="h-10 w-auto" />
      </Navbar.Brand>
      <div className="flex md:order-2">
        {user ? (
          <Dropdown
            arrowIcon={false}
            inline={true}
            label={
              (user.photoURL && user.photoURL !== "") ? (
                <img
                  src={user.photoURL}
                  className="rounded h-10"
                  alt="User Avatar"
                />
              ) : (
                <MdOutlineAccountCircle size={30} />
              )
            }
          >
            <Dropdown.Header>
              <span className="block text-sm">{user.displayName}</span>
              <span className="block truncate text-sm font-medium">
                {user.email}
              </span>
            </Dropdown.Header>
            <Dropdown.Item className="text-center">
              <LogoutButton />
            </Dropdown.Item>
          </Dropdown>
        ) : (
          <LoginButton className="hidden md:block" />
        )}
        <Navbar.Toggle className="text-white"/>
      </div>
      <Navbar.Collapse className="text-white">
        <Navbar.Link href="/" color="white" className="text-white">
          Home
        </Navbar.Link>
        {user && <Navbar.Link href="/tools" className="text-white">Tools</Navbar.Link>}
        <Navbar.Link href="/about" className="text-white">About</Navbar.Link>
        {/* <Navbar.Link href="/contact" className="text-white">Contact</Navbar.Link> */}
        {!user && (
          <div className="md:hidden pt-4 self-center">
            <LoginButton />
          </div>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
