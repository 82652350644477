import React from 'react';
import { loginWithGoogle, auth } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';

interface LoginButtonProps {
  className?: string;
}

export const LoginButton: React.FC<LoginButtonProps> = ({ className }) => {
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await loginWithGoogle();
      auth.onAuthStateChanged((user) => {
        if (user) {
          navigate('/tools');
        }
      });
    } catch (error: any) {
      if (error.code === 'auth/popup-closed-by-user') {
        console.log('Sign-in popup was closed by the user.');
        // Optionally, handle the case when the popup is closed by the user
      } else {
        console.error('Error during sign-in:', error);
        // Handle other errors differently
      }
    }
  };

  return (
    <button
      onClick={handleLogin}
      className={`flex items-center px-4 py-2 space-x-2 text-white bg-blue-600 rounded hover:bg-blue-700 ${className}`}
    >
      <span>Sign in with Google</span>
    </button>
  );
};
