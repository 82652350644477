import React from 'react';
import { Alert } from 'flowbite-react';

const BetaDisclaimer = () => {
  return (
    <Alert
      color="yellow"
      className="mb-6 mx-auto"
    >
      <span>
        <strong className="mr-1">Experimental Feature Alert!</strong>
        Please note that this tool is in its beta phase. AI technology can sometimes be unpredictable and produce unexpected results.
      </span>
    </Alert>
  );
};

export default BetaDisclaimer;
