import React, { useState } from 'react';

interface CopyToClipboardButtonProps {
  textToCopy: string;
  className?: string;
}

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({ textToCopy, className }) => {
  const [buttonText, setButtonText] = useState('Copy');

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setButtonText('Copied!');
      setTimeout(() => setButtonText('Copy'), 3000);
    } catch (err) {
      setButtonText('Failed to Copy');
      setTimeout(() => setButtonText('Copy'), 3000);
    }
  };

  return (
    <button onClick={copyToClipboard} className={`${className} bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300`}>
      {buttonText}
    </button>
  );
};

export default CopyToClipboardButton;
