import { Select, Label, Button, TextInput, Checkbox } from "flowbite-react";
import Switch from "./Switch";
import React, { useState } from "react";
import Tooltip from "./Tooltip";
import { ArticulationWordListInput } from "slp-tools-shared";

interface ArticulationWordListGeneratorProps {
  onSubmit: (parameters: ArticulationWordListInput) => void;
}

const sounds = {
  "Early developing sounds": [
    "p",
    "b",
    "d",
    "m",
    "n",
    "h",
    "w",
    "t",
    "k",
    "g",
    "ng",
    "f",
    "y",
  ],
  "Later developing sounds": [
    "v",
    "s",
    "z",
    "sh",
    "ch",
    "j",
    "l",
    "voiced th",
    "voiceless th",
    "zh",
  ],
  "S-blends": ["SK", "SL", "SM", "SN", "SP", "ST", "SW"],
  "L-blends": ["BL", "FL", "GL", "KL", "PL"],
  "R-blends": ["BR", "KR", "DR", "FR", "GR", "PR", "STR", "TR"],
  "/r/ sounds": ["prevocalic r", "er", "or", "ar", "air", "ear", "ire"],
};

const phonemePositions = ["initial", "medial", "final", "all positions"];

const ArticulationWordListGenerator: React.FC<
  ArticulationWordListGeneratorProps
> = ({ onSubmit }) => {
  const [position, setPosition] = useState(phonemePositions[0]);
  const [themeInterest, setThemeInterest] = useState("");
  const [targetSound, setTargetSound] = useState<string>("p"); // 'p' as the default value in an array
  const [numberOfWords, setNumberOfWords] = useState("10");
  const [includeMultiSyllabic, setIncludeMultiSyllabic] = useState(false);
  const [includeCVC, setIncludeCVC] = useState(false);
  const [selectedPairs, setSelectedPairs] = useState<
    Array<{ position: string; sound: string }>
  >([]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const articulationWordListData: ArticulationWordListInput = {
      position,
      themeInterest,
      targetSound,
      numberOfWords,
      includeMultiSyllabic,
      includeCVC,
      selectedPairs,
    };

    onSubmit(articulationWordListData);
  };

  const handleAddPair = () => {
    setSelectedPairs([...selectedPairs, { position, sound: targetSound }]);
    // Reset the selection (optional)
    setPosition(phonemePositions[0]);
    setTargetSound("p");
  };

  const handleRemovePair = (index: number) => {
    const newPairs = [...selectedPairs];
    newPairs.splice(index, 1);
    setSelectedPairs(newPairs);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 w-full max-w-md mx-auto">
      <div className="flex flex-col space-y-2">
        <div className="flex items-center">
          <Label
            htmlFor="numberOfWords"
            value="Number of Words"
            className="text-left"
          />
          <Tooltip text="Number of words you'd like to generate." />
        </div>

        <TextInput
          id="numberOfWords"
          type="number"
          placeholder="Number of Words"
          min="1"
          value={numberOfWords}
          onChange={(e) => setNumberOfWords(e.target.value)}
          required={true}
          className="w-full"
          max="20"
        />
      </div>
      <div className="flex flex-col space-y-2">
        <div className="flex items-center">
          <Label
            htmlFor="themeInterest"
            value="Theme/Interest (optional)"
            className="text-left"
          />
          <Tooltip text="Provide a theme or interest to generate words related to that topic." />
        </div>
        <TextInput
          id="themeInterest"
          type="text"
          placeholder="Theme/Interest"
          value={themeInterest}
          onChange={(e) => setThemeInterest(e.target.value)}
          className="w-full"
        />
      </div>
      <div className="flex flex-col space-y-4">
        <Label value="Word Types (optional)" className="text-left" />
        <div className="flex flex-col space-y-4">
          <div className="flex items-center">
            <Switch
              id="includeMultiSyllabic"
              checked={includeMultiSyllabic}
              onChange={(e) => setIncludeMultiSyllabic(e.target.checked)}
              className="mr-2"
            />
            <Label
              htmlFor="includeMultiSyllabic"
              value="Include Multi-syllabic Words"
              className="text-left"
            />
            <Tooltip text="Toggle to include multi-syllabic words in the generated list." />
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="flex items-center">
            <Switch
              id="includeCVC"
              checked={includeCVC}
              onChange={(e) => setIncludeCVC(e.target.checked)}
              className="mr-2"
            />
            <Label
              htmlFor="includeCVC"
              value="Include CVC Words"
              className="text-left"
            />
            <Tooltip text="Toggle to include consonant-vowel-consonant (CVC) words in the generated list." />
          </div>
        </div>
      </div>

      {/* Section Divider */}
      <hr className="my-4 border-gray-300" />

      <div className="flex flex-wrap gap-4 items-end">
        {/* Phoneme position selection */}
        <div className="flex flex-col space-y-2">
          <Label htmlFor="phonemePosition" value="Phoneme Position" />
          <Select
            id="phonemePosition"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
          >
            {phonemePositions.map((pos) => (
              <option key={pos} value={pos}>
                {pos}
              </option>
            ))}
          </Select>
        </div>

        {/* Target sound selection */}
        <div className="flex flex-col space-y-2">
          <div className="flex items-center">
            <Label htmlFor="targetSound" value="Target Sound" />
            <Tooltip text="Select the target sound to practice." />
          </div>
          <Select
            id="targetSound"
            value={targetSound}
            onChange={(e) => setTargetSound(e.target.value)}
            className="w-full"
          >
            {Object.entries(sounds).map(([category, soundsArray]) => (
              <optgroup key={category} label={category}>
                {soundsArray.map((sound) => (
                  <option key={sound} value={sound}>
                    {sound}
                  </option>
                ))}
              </optgroup>
            ))}
          </Select>
        </div>

        {/* Add pair button */}
        <Button type="button" onClick={handleAddPair} color="green">
          Add Pair
        </Button>
      </div>

      {/* Selected Pairs Section */}
      <div className="flex flex-col space-y-2">
        <div className="p-4 border border-gray-300 rounded-lg">
          {selectedPairs.length > 0 ? (
            <ul className="list-disc list-inside">
              {selectedPairs.map((pair, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center mb-2"
                >
                  <span className="font-medium">
                    <span className="text-gray-600">Position:</span>{" "}
                    {pair.position},
                    <span className="text-gray-600"> Sound:</span> {pair.sound}
                  </span>
                  <Button
                    type="button"
                    onClick={() => handleRemovePair(index)}
                    color="red"
                    size="xs"
                    className="ml-2"
                  >
                    Remove
                  </Button>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500">
              No pairs selected. Add pairs to see them listed here.
            </p>
          )}
        </div>
      </div>

      <Button type="submit" color="purple">
        Generate List
      </Button>
    </form>
  );
};

export default ArticulationWordListGenerator;
