import React, { useEffect, useState } from "react";
import { TextInput, Button, Label, Textarea, Select } from "flowbite-react";
import { SocialStoryInput } from "slp-tools-shared";
import TooltipComponent from "./Tooltip";

interface SocialStoryGeneratorProps {
  onSubmit: (parameters: SocialStoryInput) => void;
}

const gradeLevels = [
  "Pre-K",
  "Kindergarten",
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
  "11th",
  "12th",
];

const tooltipContent = [
  "Setting expectations for a field trip",
  "Responding to classroom incidents like climbing on desks",
  "Adjusting to a new lunch schedule",
  "Transitioning between classes",
];

const SocialStoryGenerator: React.FC<SocialStoryGeneratorProps> = ({
  onSubmit,
}) => {
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [gradeLevel, setGradeLevel] = useState(gradeLevels[0]);
  const [interests, setInterests] = useState("");
  const [storyContext, setStoryContext] = useState("");

  const [storyContextPlaceholder, setStoryContextPlaceholder] = useState("");
  useEffect(() => {
    // Set a random placeholder on component mount
    const randomIndex = Math.floor(Math.random() * storyContextExamples.length);
    setStoryContextPlaceholder(storyContextExamples[randomIndex]);
  }, []); // The empty array ensures this effect only runs once on mount

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // build out the parameters object from the state
    const generatedPrompt: SocialStoryInput = {
      name,
      age: age,
      gradeLevel,
      interests,
      storyContext,
    };
    onSubmit(generatedPrompt);
  };

  const storyContextExamples = [
    "E.g., We're going on a field trip, and I want to set behavior expectations.",
    "E.g., They were climbing on desks in class.",
    "E.g., We're introducing a new lunch schedule.",
    "E.g., Transitioning between classes and activities.",
  ];

  return (
    <form onSubmit={handleSubmit} className="space-y-4 w-full max-w-md mx-auto">
      {/* Story Context Input */}
      <div className="flex flex-col space-y-2">
        <div className="flex items-center">
          <Label
            htmlFor="storyContext"
            value="Story Context"
            className="text-left"
          />
          <TooltipComponent text="Provide specific details for the social story you need, such as a scenario or behavior to address." />
        </div>
        <Textarea
          id="storyContext"
          placeholder={storyContextPlaceholder}
          value={storyContext}
          onChange={(e) => setStoryContext(e.target.value)}
          required={true} // This is the only required field
          className="w-full resize-y"
          rows={3}
        />
      </div>

      {/* Name */}
      <div className="flex flex-col space-y-2">
        <Label htmlFor="name" value="Name" className="text-left" />
        <TextInput
          id="name"
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full"
        />
      </div>
      {/* Age */}
      <div className="flex flex-col space-y-2">
        <Label htmlFor="age" value="Age" className="text-left" />
        <TextInput
          id="age"
          type="number"
          placeholder="Age"
          min="0"
          value={age}
          onChange={(e) => setAge(e.target.value)}
          className="w-full"
        />
      </div>
      {/* Grade level */}
      <div className="flex flex-col space-y-2">
        <Label htmlFor="gradeLevel" value="Grade Level" className="text-left" />
        <Select
          id="gradeLevel"
          value={gradeLevel}
          onChange={(e) => setGradeLevel(e.target.value)}
          className="w-full text-base"
        >
          {gradeLevels.map((grade) => (
            <option key={grade} value={grade}>
              {grade}
            </option>
          ))}
        </Select>
      </div>
      {/* Interests */}
      <div className="flex flex-col space-y-2">
        <Label htmlFor="interests" value="Interests" className="text-left" />
        <TextInput
          id="interests"
          type="text"
          placeholder="Interests"
          value={interests}
          onChange={(e) => setInterests(e.target.value)}
          className="w-full"
        />
      </div>

      <Button type="submit" color="purple">
        Generate Story
      </Button>
    </form>
  );
};

export default SocialStoryGenerator;
