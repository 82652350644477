import React from "react";
import { FaTools } from 'react-icons/fa'; // Import a construction-related icon from react-icons

// You can use the FaTools icon in your code


const About: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8 bg-gray-100">
      <h1 className="text-4xl font-semibold mb-8 text-center text-gray-800">
        About Us
      </h1>
      <div className="flex flex-col items-center bg-white p-8 rounded-lg shadow-lg">
        {/* Construction icon from Heroicons */}
        <FaTools className="h-16 w-16 text-gray-500 mb-4" />
        <h2 className="text-2xl font-semibold mb-4">Under Construction</h2>
        <p className="mb-6 text-gray-700 text-lg leading-relaxed">
          This page is under construction. Stay tuned for updates!
        </p>
      </div>
    </div>
  );
};

export default About;
