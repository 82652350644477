// src/components/NumberedList.tsx
import React, { useRef } from 'react';

interface NumberedListProps {
  words: string[];
}

const NumberedList: React.FC<NumberedListProps> = ({ words }) => {
  const listRef = useRef<HTMLOListElement>(null);

  return (
    <>
      <ol ref={listRef} className="list-decimal list-inside px-6 mt-4">
        {words.map((word, index) => (
          <li
            key={index}
            className="py-1 text-lg font-medium text-gray-700 bg-white rounded-md shadow-md my-2 p-3"
          >
            {word}
          </li>
        ))}
      </ol>
    </>
  );
};

export default NumberedList;
