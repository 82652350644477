import { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import firebase from "firebase/auth";
import { auth } from "./firebaseConfig"

interface AuthState {
  loading: boolean;
  user: firebase.User | null;
}

export const useAuth = (): AuthState => {
  const [state, setState] = useState<AuthState>({ loading: true, user: null });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setState({ loading: false, user });
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return state;
};