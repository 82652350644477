import React, { useState, createContext, useContext, useEffect } from "react";
import { loginWithGoogle, auth } from "../firebaseConfig";

// ... rest of the code
interface AuthContextType {
  user: any;
  signin: (callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}

const AuthContext = createContext<AuthContextType>(null!);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const signin = (callback: VoidFunction) => {
    loginWithGoogle()
      .then((result) => {
        setUser(result.user);
        callback();
      })
      .catch((error) => {
        console.error("Error signing in with Google:", error);
      });
  };

  const signout = (callback: VoidFunction) => {
    auth
      .signOut()
      .then(() => {
        setUser(null);
        callback();
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  const value = { user, signin, signout };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}

// ... rest of the code
