import React, { useState } from "react";
import SocialStoryGenerator from "../components/SocialStoryGenerator";
import { SocialStoryInput } from "slp-tools-shared";
import { FUNCTION_NAMES } from "slp-tools-shared";
import { app } from "../firebaseConfig";
import { getFunctions, httpsCallable } from "firebase/functions";
import BetaDisclaimer from "../components/BetaDisclaimerComponent";
import { Toast } from "flowbite-react";
import CopyToClipboardButton from "../components/CopyToClipboardButton";

export const SocialStoryPage: React.FC = () => {
  const [generatedStory, setGeneratedStory] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Copy Story");

  const handlePromptSubmit = async (paramaters: SocialStoryInput) => {
    callSocialStoryGenerator(paramaters).then((response) =>
      setGeneratedStory(response)
    );
  };

  const callSocialStoryGenerator = async (
    paramaters: SocialStoryInput
  ): Promise<string> => {
    setIsLoading(true);
    try {
      const functionsInstance = getFunctions(app);
      const socialStoryFunction = httpsCallable(
        functionsInstance,
        FUNCTION_NAMES.socialStoryGenerator
      );
      const result = await socialStoryFunction(paramaters);
      const responseData = result.data as string;
      return responseData;
    } catch (error) {
      return "";
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main className="container mx-auto px-4 py-8">
      <BetaDisclaimer />
      <section className="text-center mb-8">
        <h2 className="text-4xl font-bold mb-4">Social Story Generator</h2>
        <p className="text-xl font-semibold">
          Create customized social stories to support your clients.
        </p>
      </section>
      <div className="flex flex-col">
        <section className="w-full mb-8">
          <SocialStoryGenerator onSubmit={handlePromptSubmit} />
        </section>
        <section className="w-full">
          {isLoading ? (
            <div className="flex justify-center items-center mt-8">
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
            </div>
          ) : generatedStory ? (
            <div className="bg-gray-100 p-6 rounded-md">
              <p className="whitespace-pre-line fade-in">{generatedStory}</p>
            </div>
          ) : null}
        </section>
      </div>
      {generatedStory && (
      <CopyToClipboardButton textToCopy={generatedStory}/>
    )}
    </main>
  );
};
